<script>
import AppBannerSSK from '@/components/shared/BannerSSK.vue';
import HeaderMenuSSK from '@/components/shared/AppHeaderSSK.vue';
import Tutorial from '@/components/shared/TimelineTutorial.vue';
import AppFooter from '@/components/shared/AppFooter';


export default {
	name: 'Sousenkyo',
	components: {
		HeaderMenuSSK,
		AppBannerSSK,
		Tutorial,
		AppFooter,
	},
	data() {
		return {
			isOpen: false,
			isScrolled: false,
		};

	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll() {
			// Menandai apakah halaman telah di-scroll ke bawah untuk menambahkan background pada header
			this.isScrolled = window.scrollY > 50;
		}
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}
};
</script>

<template>
	<!-- Banner -->
	<HeaderMenuSSK
		:class="['absolute top-0 md:left-36 z-50 w-full items-center', isScrolled ? 'bg-transparent' : 'bg-transparent']" />

	<!-- Banner -->
	<AppBannerSSK />

	<section id="default-font-page">


		<section id="default-font-page"
			class="bg-center bg-no-repeat bg-[url('@/assets/images/howtovote/ctabg.jpeg')] bg-gray-700 bg-blend-multiply">
			<div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
				<h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
					Together, Collaboration for One Goal</h1>
				<div class="flex flex-col space-y-4 my-10 items-center">
					<div
						class="inline-flex items-center justify-center capitalize leading-none text-xl font-bold rounded-lg px-10 py-5 bg-blue-700 text-white w-full max-w-lg">
						<p>1st Pre-result: 12th Rank - 8.270 Vote</p>
					</div>
					<div
						class="inline-flex items-center justify-center capitalize leading-none text-xl font-bold rounded-lg px-10 py-5 bg-emerald-500 text-white w-full max-w-lg">
						<p>2nd Pre-result: ?</p>
					</div>
					<div
						class="inline-flex items-center justify-center capitalize leading-none text-xl font-bold rounded-lg px-10 py-5 bg-emerald-500 text-white w-full max-w-lg">
						<p>Final Result: ?</p>
					</div>
				</div>
				<p class="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">Oniel is taking steps to
					realize his dreams! With your help, she will go further and shine brighter. Let's
					collaborate to realize one of his dreams and step together towards the horizon <b>#ONTIME
					</b></p>
				<div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 space-x-3">
					<a href="http://ssk.jkt48.com/2024"
						class="inline-flex justify-center items-center py-3 px-5 text-base font-bold text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
						Use Your Vote!
					</a>
					<a href="https://ssk.corsyava.com/formulir-donatur" target="_blank"
						class="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
						Donate here (managed by Onielity)
					</a>
				</div>
			</div>
		</section>

		


		<div class="w-full max-w-screen-lg mx-auto p-3" id="whyoniel">
			<h1 class="text-5xl font-semibold text-black dark:text-white text-center my-10">Why Oniel?</h1>
			<div class="my-6 border-t border-gray-300"></div>
			<!-- Card List Container -->
			<div class="space-y-6">
				<!-- Card 1 -->
				<router-link to="/onieland-recap">
					<div
						class="grid grid-cols-1 md:grid-cols-2 bg-gray-200 dark:bg-gray-700 shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 hover:scale-125 ease-in duration-150">
						<div class="p-4">
							<h2 class="text-3xl font-semibold text-black dark:text-white text-left">#OnieLand: Song
								Cover
								Content</h2>
							<p class="text-left text-xl text-gray-800 dark:text-gray-400 mt-2 mr-20">Her singing ability
								is
								unquestionable. The
								#Onieland content she uploaded has proven that she is worthy of the ranking. See her
								cover here
							</p>
						</div>
						<div>
							<img src="@/assets/images/whyoniel-1.png" alt="Image 2" class="w-full h-48 object-cover">
						</div>
					</div>
				</router-link>
				<!-- Card 1 -->
				<router-link to="/botoniel">
					<div
						class="grid grid-cols-1 md:grid-cols-2 bg-gray-200 dark:bg-gray-700 shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 hover:scale-125 ease-in duration-150">
						<div class="p-4">
							<h2 class="text-3xl font-semibold text-black dark:text-white text-left">Master of Dad Jokes
							</h2>
							<p class="text-left text-xl text-gray-800 dark:text-gray-400 mt-2 mr-20">Her ability to play
								with words and break our imagination, making us laugh and fall in love with her. Click
								here to access BotOniel</p>
						</div>
						<div>
							<img src="@/assets/images/whyoniel-6.png" alt="Image 2" class="w-full h-48 object-contain">
						</div>
					</div>
				</router-link>
				<!-- Card 2 -->
				<div
					class="grid grid-cols-1 md:grid-cols-2 bg-gray-200 dark:bg-gray-700 shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 hover:scale-125 ease-in duration-150">
					<div class="p-4 align-middle">
						<h2 class="text-3xl font-semibold text-black dark:text-white text-left">Renai Kinshi Jourei -
							Global Center</h2>
						<p class="text-left text-xl text-gray-800 dark:text-gray-400 mt-2 mr-20">Earning the trust of
							being the one standing at the front, leading a performance with a positive spirit to spread
							to the audience</p>
					</div>
					<div>
						<img src="@/assets/images/whyoniel-2.png" alt="Image 2" class="w-1/2 h-48 ml-32 object-cover">
					</div>
				</div>
				<!-- Card 3 -->
				<router-link to="/what-song-oniel-play">
					<div
						class="grid grid-cols-1 md:grid-cols-2 bg-gray-200 dark:bg-gray-700 shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 hover:scale-125 ease-in duration-150">
						<div class="p-4 align-middle">
							<h2 class="text-3xl font-semibold text-black dark:text-white text-left">Showroom/IDN Live
								Playlist</h2>
							<p class="text-left text-xl text-gray-800 dark:text-gray-400 mt-2 mr-20">Her vast musical
								knowledge gives you a new perspective on the world's songs. See her cover list. Click to
								see her
								playlist</p>
						</div>
						<div>
							<img src="@/assets/images/whyoniel-3.png" alt="Image 2"
								class="w-1/2 h-48 ml-32 object-cover">
						</div>
					</div>
				</router-link>
				<!-- Card 4 -->
				<div
					class="grid grid-cols-1 md:grid-cols-2 bg-gray-200 dark:bg-gray-700 shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 hover:scale-125 ease-in duration-150">
					<div class="p-4 align-middle">
						<h2 class="text-3xl font-semibold text-black dark:text-white text-left">Slap Show: Ramadan Show
						</h2>
						<p class="text-left text-xl text-gray-800 dark:text-gray-400 mt-2 mr-20">She and her friend were
							entrusted with hosting a talk show, making it one of the most beloved by the audience.</p>
					</div>
					<div>
						<img src="@/assets/images/whyoniel-4.png" alt="Image 2" class="w-1/2 h-48 ml-32 object-contain">
					</div>
				</div>
				<!-- Card 5 -->
				<div
					class="grid grid-cols-1 md:grid-cols-2 bg-gray-200 dark:bg-gray-700 shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 hover:scale-125 ease-in duration-150">
					<div class="p-4 align-middle">
						<h2 class="text-3xl font-semibold text-black dark:text-white text-left">A friendly and
							positive-vibe persona</h2>
						<p class="text-left text-xl text-gray-800 dark:text-gray-400 mt-2 mr-20">She can bring warm and
							meaningful conversations. Maybe she will remember you!</p>
					</div>
					<div>
						<img src="@/assets/images/whyoniel-5.png" alt="Image 2" class="w-1/2 h-48 ml-32 object-cover">
					</div>
				</div>
			</div>
			<div class="my-6 border-t border-gray-300"></div>
		</div>
	</section>

	<Tutorial id="howtovote" />




	<AppFooter class="items-center" />

</template>

<style scoped>
#bannersec {
	background: url(@/assets/images/background-images/oniel-ssk.jpg);
	width: 100vw;
	height: 100vh;
	/* Adjust the height as needed */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

#default-font-page {
	font-family: 'Plus Jakarta Sans', sans-serif;
}
</style>

<!--
<a href="http://ssk.jkt48.com/2024/id" target="_blank"
							class="font-bold items-left px-14 py-4 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
							aria-label="BotOniel">
							<Button title="Access to General Election Website" />
                </a>
-->