<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section id="ssk" class="flex flex-row items-end default-font-page">
		<div class="w-full md:w-1/3 mt-12 md:ml-12 max-[640px]:m-10">
			<div class="md:justify-start mb-10 sm:mt-20 justify-center flex">
				<router-link to="/general-election"
					class="font-bold items-left px-8 py-6 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
					aria-label="BotOniel">
					<button type="button">Get to Know</button>
				</router-link>
			</div>
			<h1 class="font-bold text-3xl md:text-3xl xl:text-4xl text-center md:text-left text-black mb-10 mt-10">
				Cornelia Vanisa for JKT48 26th Single General Election
			</h1>
			<p
				class="font-general-medium mt-2 text-2xl md:text-3xl xl:text-4xl text-center md:text-left text-black mt-10">
				“A vote is like a rifle: its usefulness depends upon the character of the user.”
			</p>
			<p
				class="font-bold xl:mt-2 text-lg sm:text-xl xl:text-xl text-center md:text-left leading-none md:text-primary-dark text-white mb-28 mt-5">
				Theodore Roosevelt
			</p>
		</div>
	</section>
</template>

<style scoped>
#ssk {
	background-image: url(@/assets/images/background-images/oniel-ssk.jpg);
	width: 100vw;
	height: 120vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.default-font-page {
	font-family: 'Plus Jakarta Sans', sans-serif;
}

/* Media query untuk tampilan mobile */
@media (max-width: 640px) {
	#ssk {
		background-position: right -300px top 0px; /* Mengubah posisi background ke kanan pada tampilan mobile */
	}
}
</style>

<!--
<a href="http://ssk.jkt48.com/2024/id" target="_blank"
				class="font-bold items-left px-8 py-6 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
				aria-label="BotOniel">
				<button type="button">1st Pre-result: 8.270 Vote (12th Rank)</button>
			</a>
			<p
				class="font-bold mt-8 text-lg sm:text-xl xl:text-2xl text-center md:text-left leading-none text-white mt-10 mb-10">
				“Maju Oshimennya, Bahagia Pendukungnya!”
			</p>
-->
