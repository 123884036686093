<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section id="bannerthird" class="flex flex-row items-end">
			<section class="flex flex-col sm:justify-between items-center sm:flex-row">
				<div class="w-full md:ml-10 mb-24 md:w-1/3 items-center">
					<div class="md:justify-start sm:mb-20 sm:mt-20 justify-center flex">
						<router-link to="/what-song-oniel-play"
							class="font-bold items-left px-14 py-4 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
							aria-label="WSOP">
							<button type="button">What Song Oniel Play?</button>
						</router-link>
					</div>
					<h1
						class="font-bold text-3xl md:text-3xl xl:text-4xl text-center md:text-left text-black mb-10 mt-10">
						What Song Oniel Play?
					</h1>
					<h2 class="font-general-medium mt-2 text-2xl md:text-3xl xl:text-4xl text-center md:text-left text-black mt-10
				">
						“Music expresses that which cannot be put into words and that which cannot remain silent.”
					</h2>
					<p
						class="font-bold mt-2 text-lg sm:text-xl xl:text-xl text-center md:text-left leading-none text-black mt-10 mb-10">
						Victor Hugo
					</p>
				</div>
			</section>
		</section>
</template>

<style scoped>
#bannerthird {
	background-image: url(@/assets/images/background-images/oniel-3.jpg);
	width: 100vw;
	height: 100vh;
	/* Adjust the height as needed */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

/* Media query untuk tampilan mobile */
@media (max-width: 640px) {

	section {
		background-position: right 300px top 0px;
		/* Mengubah posisi background ke kanan pada tampilan mobile */
	}
}
</style>

<!--
<a href="http://ssk.jkt48.com/2024/id" target="_blank"
				class="font-bold items-left px-8 py-6 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
				aria-label="BotOniel">
				<button type="button">1st Pre-result: 8.270 Vote (12th Rank)</button>
			</a>
			<p
				class="font-bold mt-8 text-lg sm:text-xl xl:text-2xl text-center md:text-left leading-none text-white mt-10 mb-10">
				“Maju Oshimennya, Bahagia Pendukungnya!”
			</p>
-->
