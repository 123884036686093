<template>
    <section class="dark:text-gray-800">
        <div class="container max-w-5xl px-4 py-12 mx-auto">
            <div class="grid gap-4 mx-4 sm:grid-cols-12">
                <div class="col-span-12 sm:col-span-3">
                    <div
                        class="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-violet-600">
                        <h3 class="text-3xl font-semibold text-gray-500 dark:text-white">How to Vote Oniel</h3>
                        <span class="text-sm font-bold tracking-wider uppercase text-black dark:text-gray-400">on JKT48 General
                            Election Website</span>
                    </div>
                </div>
                <div class="relative col-span-12 px-4 space-y-6 sm:col-span-9">
                    <div
                        class="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-300">
                        <div
                            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-600">
                            <h3 class="text-xl text-left font-semibold tracking-wide text-gray-500 dark:text-white">Go to Website and Input Your Vote Code</h3>
                            <p class="text-left mt-3 text-gray-800 dark:text-white">Visit ssk.jkt48.com and select the “Vote” menu, then enter your voting code (voting codes can be obtained by purchasing certain products on the JKT48.com website).</p>
                            <img src="@/assets/images/howtovote/1.png" class="hover:scale-150 ease-in duration-150">
                        </div>
                        <div
                            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-600">
                            <h3 class="text-left text-xl font-semibold tracking-wide text-gray-500 dark:text-white">Add your vote to Oniel!</h3>
                            <p class="text-left mt-3 text-gray-800 dark:text-white">If the vote code is valid, you will be redirected to a page that displays the participating members. Also listed is the number of votes they have. Search for “Cornelia Vanisa” and press the “+” button accordingly. A pop-up modal will appear that will display the number of votes to be cast.</p>
                                <img src="@/assets/images/howtovote/2.png" class="hover:scale-150 ease-in duration-150">
                        </div>
                        <div
                            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-600">
                            <h3 class="text-left text-xl font-semibold tracking-wide text-gray-500 dark:text-white">Your vote has added</h3>
                            <p class="text-left mt-3 text-black dark:text-white">The votes have been cast! Let's wait for the announcement of the stages up to the finals. May our votes help Oniel fulfill his dreams!</p>
                                <img src="@/assets/images/howtovote/3.png" class="hover:scale-150 ease-in duration-150">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>