<script>
import AppHeader from '@/components/shared/AppHeader';
import ProjectGallery from '../components/projects/ProjectGallery.vue';
import AppFooter from '@/components/shared/AppFooter';
import axios from 'axios';

export default {
  name: 'BotOniel',
  components: {
    AppHeader,
    ProjectGallery,
    AppFooter,
  },
  data() {
    return {
      data: {
        jokes: 'Klik button "Niel, Jokes Niel"',
      },
      isLoading: false,  // Properti untuk melacak status loading
      buttonText: 'Niel, Jokes Niel',  // Properti untuk mengubah teks tombol
      projectImages: [
        {
          id: 1,
          img: require('@/assets/images/botoniel/ss-01.jpg'),
        },
        {
          id: 2,
          img: require('@/assets/images/botoniel/ss-02.jpg'),
        },
        {
          id: 3,
          img: require('@/assets/images/botoniel/ss-03.jpg'),
        },
      ],
      projectInfo: {
        projectDetailsHeading: 'Behind The Story: Kumpulan Humor Ala Oniel',
        projectDetails: [
          {
            id: 1,
            details:
              'Ide buku jokes Oniel sebenarnya bukan murni dari saya, tetapi pertama kali tercetus oleh Freya di MC Seishun Girls 29 Juli 2022. Karena belum ada yang merealisasikan dan terpancing kembali oleh sebuah reply di Januari 2023, ide ini akhirnya coba diwujudkan',
          },
          {
            id: 2,
            details:
              'Eksekusi dimulai sekitaran akhir Januari 2023. Dimulai dari mengumpulkan video kompilasi jokes Oniel yang beredar di YouTube maupun Tiktok. Saya sangat berterima kasih dan hats off bagi para konten kreator dan timestamp-er yang telah mengumpulkan momen-momennya.',
          },
          {
            id: 3,
            details:
              'Setelah terkumpul, baru dilakukan penyaringan. Penyaringan pertama dalam memilih jokes yang akan dibukukan adalah memastikan apakah jokes tersebut tetap lucu saat konteksnya dibawa dalam bentuk teks. Dengan asumsi bahwa pembaca bukunya tidak mengikuti Oniel sebelumnya. Setelahnya adalah penyaringan kedua, dengan memastikan tanggal dan event/post jokes tersebut keluar. Di sini lumayan menguras tenaga dan waktu, karena harus mencari dan menonton satu per satu video/cuplikan event untuk memastikan jokes tersebut benar-benar riil dari Oniel',
          },
          {
            id: 4,
            details:
              'Karena libur kuliah, pengerjaannya bisa ngebut hanya dalam dua minggu. Setelah kuliah, proyek ini sempat dikesampingkan. Gift tersebut diserahkan saat MnG Festival Nice To See You (Mei 2023), namun hampir 2 bulan tidak ada kabar hingga Juli 2023.',
          },
          {
            id: 5,
            details:
              '18 Juli, buku tersebut terlihat di antara fanletter yang baru di-share di Instagram Story. Baru pada 24 Juli 2024, ia mengunggah Instagram Story yang memamerkan gift buku jokes Oniel dan mendapat respon positif dari fandom maupun member.',
          },
        ],
      },
    };
  },
  methods: {
    async fetchData() {
      this.isLoading = true;  // Set status loading menjadi true
      this.buttonText = 'Loading...';  // Ganti teks tombol menjadi "Loading..."
      try {
        const response = await axios.get('https://script.google.com/macros/s/AKfycbz5LqkIhDuZ1KlFbajv9nWGjr8Fyh_5He-1itgmqwJmD3Mi9Mr68KpgeiC36ImW2bdc5Q/exec'); // Ganti dengan URL endpoint Anda
        this.data = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;  // Set status loading menjadi false
        this.buttonText = 'Niel, Jokes Niel';  // Kembalikan teks tombol ke "Get Data"
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('id-ID', options);
    }
  }
};
</script>

<template>
  <!-- Banner Information -->
  <div id="sticky-banner" tabindex="-1"
			class="fixed top-0 start-0 z-50 flex justify-between w-full p-4 border-b border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
			<div class="flex items-center mx-auto">
				<p class="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
					<span
						class="inline-flex p-1 me-3 bg-gray-200 rounded-full dark:bg-gray-600 w-6 h-6 items-center justify-center flex-shrink-0">
						<svg class="w-3 h-3 text-gray-500 dark:text-gray-400" aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
							<path
								d="M15 1.943v12.114a1 1 0 0 1-1.581.814L8 11V5l5.419-3.871A1 1 0 0 1 15 1.943ZM7 4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v5a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V4ZM4 17v-5h1v5H4ZM16 5.183v5.634a2.984 2.984 0 0 0 0-5.634Z" />
						</svg>
						<span class="sr-only">Light bulb</span>
					</span>
					<span>Don't Forget to vote Oniel on General Election JKT48 26th Single <a href="https://ssk.jkt48.com/2024/" target="_blank" class="font-bold underline">here</a></span>
				</p>
			</div>
			<div class="flex items-center">
				<button data-dismiss-target="#sticky-banner" type="button"
					class="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
					<svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
						viewBox="0 0 14 14">
						<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
							d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
					</svg>
					<span class="sr-only">Close banner</span>
				</button>
			</div>
		</div>

  <!-- App Header -->
  <AppHeader class="items-center mb-24 px-10 mt-24" /> <!-- hapus mt-24 setelah banner dihapus -->

  <!-- Card Container Start -->
  <div class="container mx-auto">
    <div class="text-center border-b border-primary-light
					dark:border-secondary-dark">
      <p
        class="font-general-semibold text-2xl sm:text-5xl font-semibold text-ternary-dark dark:text-ternary-light mb-4">
        BotOniel Jokes Generator
      </p>
      <h3 class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				">
        Temukan Jokes Terbaik Oniel di sini!
      </h3>
      <div class="flex justify-center gap-5 mb-10 p-5">
        <a href="https://t.me/botnielbot" target="_blank"
          class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="send" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Akses Bot Telegram</span></a>
        <router-link to="/botoniel/video"
          class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="book" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Bot Versi Video</span>
        </router-link>
      </div>
    </div>
    <div class="container mt-10 sm:gap-10 p-5">
      <div
        class="md:mt-10 h-92 p-5 border border-indigo-200 dark:border-ternary-dark shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h1 class="font-general-semibold text-4xl mt-2">{{ this.data.jokes }}</h1>
        </div>
        <p class="mt-2 text-xl p-5">({{ this.data.event }} - {{ formatDate(this.data.date) }})</p>
        <div class="flex justify-center gap-5">
          <a @click="fetchData" :disabled="isLoading"
            class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-xl border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-sky-500 focus:ring-1 focus:ring-sky-900 hover:bg-sky-50 text-white hover:text-sky-500 duration-500">
            <span class="text-sm sm:text-lg font-bold duration-100">{{ buttonText }}</span></a>
        </div>
      </div>
    </div>

    <div class="w-full text-left mt-10 hidden md:block p-5">
      <ProjectGallery :projectImages="projectImages" />
      <p
        class="font-general-medium text-primary-dark dark:text-primary-light text-2xl font-bold mb-10 mt-14 text-center">
        {{ projectInfo.projectDetailsHeading }}
      </p>
      <p v-for="projectDetail in projectInfo.projectDetails" :key="projectDetail.id"
        class="font-general-regular mb-5 text-lg text-ternary-dark dark:text-ternary-light text-justify">
        {{ projectDetail.details }}
      </p>
    </div>
    <div class="flex justify-center gap-5 p-5">
      <a href="https://x.com/mrtjam7lewat12/status/1683474891658391558" target="_blank"
        class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
        <i data-feather="book-open" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
        <span class="text-sm sm:text-lg font-general-medium duration-100">Buku: Kumpulan Humor Ala Oniel</span></a>
      <a href="https://x.com/mrtjam7lewat12/status/1684547783406256129" target="_blank"
        class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
        <i data-feather="server" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
        <span class="text-sm sm:text-lg font-general-medium duration-100">Behind The Scene</span></a>
    </div>
    <AppFooter />
  </div>
</template>

<style scoped></style>